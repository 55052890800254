import BackButton from "../../Utils/BackButton"
import Back from "../../Assets/BBack.png"
import Prakruthi from "../../Assets/Prakruthi.png"
import Prakruthi1 from "../../Assets/Prakruthi1.png"
import Prakruthi2 from "../../Assets/Prakruthi2.png"
import Prakruthi3 from "../../Assets/Prakruthi3.png"
import View360 from "../../Assets/360view.png"
import propertyVideo from "../../Assets/propVideo.png"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import UserContext from "../UserContext"
import CalendarCombined from "../../Utils/CalendarCombined"
import MembersCount from "../../Utils/MembersCount"
import InputSelector from "../../Utils/InputSelector"
import NavButton from "../../Utils/SignButton"
import RoomSelector from "../../Utils/RoomSelector"
import Amenities from "../Amenities"
import Label from "../../Utils/Label"
import ImageTextCard from "./ImageTextCard"
import TV from "../../Assets/TV.png"
import RoomAmenities from "./RoomAmenities"
import Reviews from "./Reviews"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { GetAllProperties, GetPropertyDetail, GetRatings, GetRatingsbyDate, GetRatingsbyRate} from "../../Redux/Actions/api"
import { toast } from "react-toastify"
import { Box, CircularProgress } from "@mui/material"
import Bullet from "../../Assets/Bullet.png"
import { register } from 'swiper/element';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useEmblaCarousel from 'embla-carousel-react'

import PropCard from "../../Utils/PropCard"
import { useDispatch, useSelector } from "react-redux"
import { GetAllPropertiesAction, PropertyDetailAction, RatingAction } from "../../Redux/Actions/PropertyAction"
// import PlaceSelector from "../../Utils/PlaceSelector"
import PlaceSelectorTemp from "../../Utils/Placeselectortemp"
import Loading from "../../Utils/Loading"
import dateFormat from "dateformat"
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import PlaceSelectorDetailView from "../../Utils/PlaceSelectorForDetail"
import MembersCountDetailView from "../../Utils/MembersCountForDetail"
import { HitDetailViewApiAction, UpdateSearchQueryAction } from "../../Redux/Actions/ModalAction"
import ModalForImages from "./ModalforImages"
import ShowAll from "../../Assets/ShowAll.png"
import ModalForAmenities from "./ModalforAmenities"
import ModalForAddOns from "./ModalforAddOns"
import ModalForHotelRules from "./ModalforHotelRules"
import ModalForSafetyProperty from "./ModalforSafetyProperty"
import ModalForCancellationPolicy from "./ModalforCancellationPolicy"
import ModalForPetsFriendly from "./ModalForPetsFriendly"
import ModalFor360View from "./Modalfor360View"
import ModalForPropertyVideo from "./ModalforPropertyVideo"
import HotelRules from "../../Assets/HotelRules.svg"
import PetsAllowedImage from "../../Assets/PetsPaw.png"
import Map from "./Map"
import Fade from "react-reveal/Fade"
import LeftScroll from "../../Assets/leftImage.png"
import RightScroll from "../../Assets/rightImage.png"
import Logo from "../../Assets/Logo.png"
import smoothScrolltoTop from "../../Utils/Scroll"
import PropCardMain from "../../Utils/PropCardMain"
import ScrollRight from "../../Assets/ScrollRight.png"
import ScrollLeft from "../../Assets/ScrollLeft.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { red } from "@mui/material/colors"
import "./HTMLContentStyle.css"

const showLoader = () => {
    return (
        <Box
            className="dropin-parent"
            id="drop_in_container"
            sx={
                {
                    width: "100%",
                    height: "300px",
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }
            }
        >
            <div className="image-container">
                <img className="" src={Logo} />
            </div>
            {/* <CircularProgress /> */}
        </Box>
    );
};

const About=({propdetailnavbar,...props})=>
{
    const[selecttab,setselecttab]=useState("")
    const[memberdisplay,setmemberdisplay]=useState(false)
    const data=["Book Your Room"]
    const params = useParams();
    // const spv_id=localStorage.getItem("spv_id")
    // const brik_id = localStorage.getItem("brik_id")
    const spv_id= params?.spvid ?? ""
    const brik_id= params?.brikid ?? ""
    // const [spv_id, setspv_id] = useState(localStorage.getItem("spv_id"))
    // const [brik_id, setbrik_id] = useState(localStorage.getItem("brik_id"))
    localStorage.removeItem('discount')
    const {count,setcount,date,place}=useContext(UserContext)
    const[confirmbuttonstate,setconfirmbuttonstate]=useState(false)
    let adult=count?.adults
    let children=count?.children
    const checkin=dateFormat(date.checkin,'yyyy-mm-dd')
    const checkout=dateFormat(date.checkout,'yyyy-mm-dd')
    const [detaildata,setdetaildata]=useState([])
    const [isloading,setisloading]=useState({detail:true,rating:true})
    const [isImageLoading, setIsImageLoading]=useState(true)
    const[sortbyrating,setsortbyrating]=useState("desc")
    const[sortbydate,setsortbydate]=useState("desc")
    const[page,setpage]=useState(1)
    const[ratingdata,setratingdata]=useState([])
    const[propdata,setpropdata]=useState([])
    const[truncatedtext,settruncatedtext]=useState("")
    const[zoomimagemodal,setzoomimagemodal]=useState({lg:false,sm:false})
    const[zoomamenitiesmodal,setzoomamenitiesmodal]=useState({lg:false,sm:false})
    const[zoomaddonmodal,setzoomaddonmodal]=useState({lg:false,sm:false})
    const[zoomhotelrulesmodal,setzoomhotelrulesmodal]=useState({lg:false,sm:false})
    const[zoomsafetypropertymodal,setzoomsafetypropertymodal]=useState({lg:false,sm:false})
    const[zoomcancellationpolicymodal,setzoomcancellationpolicymodal]=useState({lg:false,sm:false})
    const[zoompetsfriendlymodal,setzoompetsfriendlymodal]=useState({lg:false,sm:false})
    const[zoom360viewmodal,setzoom360viewmodal]=useState({lg:false,sm:false})
    const[zoompropertyvideomodal,setzoompropertyvideomodal]=useState({lg:false,sm:false})
    const[id,setid]=useState("5")
    const storedata=useSelector((storedata)=>storedata)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const { searchquery, hitdetailviewapi } = storedata?.modalreducer
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const swiperRef = useRef(null)
    const handleImageLoad = () => {
        console.log('handleImageLoad:')
        setIsImageLoading(false)
    };   
    const HTMLRule = detaildata?.hotel_rules?.rule;
    const HTMLCancellation = detaildata?.cancellation_policy?.cancellationPolicy
    const HTMLSafety = detaildata?.safety_and_property?.safetyAndProperty
    const HTMLPetsFriendly = detaildata?.pets_friendly?.petsFriendly
    let urlFor360View = detaildata?.view_360?.view360
    let urlForPropertyVideo = detaildata?.property_video?.propertyVideo
    const checkContent = (content) => {
        return content && content !== "" ? true : false;
    };

    const countContent = [checkContent(HTMLRule), checkContent(HTMLSafety), checkContent(HTMLCancellation), checkContent(HTMLPetsFriendly)].filter(Boolean).length;
               
    let { brik_image } = storedata?.propertyreducer?.propertydetails
    // let brik_image = [{'image': storedata?.propertyreducer?.propertydetails?.brik_cover_image }, ...storedata?.propertyreducer?.propertydetails?.brik_image]
    // if (brik_image && brik_image[0].image != storedata?.propertyreducer?.propertydetails?.brik_cover_image) {
    //     brik_image.unshift({ 'image': storedata?.propertyreducer?.propertydetails?.brik_cover_image })
    // }
    if (brik_image) {
        brik_image = [{ 'image': storedata?.propertyreducer?.propertydetails?.brik_cover_image }, ...storedata?.propertyreducer?.propertydetails?.brik_image]
    }
    console.log('brik_image:', brik_image)

    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'AIzaSyBR6iHOjNTV2Nld54iwYNO4ZG3dFuNxhC4'
    //     // googleMapsApiKey: 'AIzaSyAhemXdADjtIK_N8-lBQ3VjhmIWxLA38vk'
    //     // googleMapsApiKey: 'AIzaSyCmC6nAMmA9ZIXRZLpYLH593C-joJmLMeo'
    //   });
    //   const mapRef = useRef();
    //   const onMapLoad = useCallback((map) => {
    //     mapRef.current = map;
    //   }, []);
    //   if (loadError) return "Error";
    //   if (!isLoaded) return "loading...";
    useEffect(()=>
    {        
        setisloading({detail:true,rating:true})
        GetPropertyDetail(spv_id,brik_id,checkin,checkout,adult,children).then((res)=>
        {
            setdetaildata(res)
            console.log(res)
            dispatch(UpdateSearchQueryAction(false))
            setconfirmbuttonstate(false)
            if (brik_id != res?.brik_id || spv_id != res?.spv_info?.id) {
                localStorage.setItem("spv_id", res?.spv_info?.id)
                localStorage.setItem("brik_id", res?.brik_id)
                // setspv_id(res?.spv_info?.id)
                // setbrik_id(res?.brik_id)
            }
            
            localStorage.setItem("totalprice",res?.total_payable_inr)
            localStorage.setItem("cgst",res?.cgst_on_total_payable_inr)
            localStorage.setItem("gst",res?.gst_on_total_payable_inr)
            localStorage.setItem("grandtotal",res?.grand_total)
            localStorage.setItem("location",res?.location)
            localStorage.setItem("property_name",res?.property_name)
            localStorage.setItem("houserules", res?.hotel_rules)
            localStorage.setItem("cancellationpolicy", res?.cancellation_policy)
            localStorage.setItem("safetyandproperty", res?.safety_and_property)
            localStorage.setItem("petsfriendly", res?.pets_friendly)
            localStorage.setItem("pets_allowed", res?.pets_allowed)
            localStorage.setItem("view_360", res?.view_360)
            localStorage.setItem("property_video", res?.property_video)
            dispatch(PropertyDetailAction(res))
            settruncatedtext(res?.description?.slice(0,200))
            setisloading({ ...isloading, detail: false })
            if (!res?.brik_availability) {
                toast.error("You just missed it! We are sold out on your dates!")
            }
            
        }).catch((e)=>{
            console.log('property_detail fetch error:', e)
            setisloading({...isloading,detail:false})
            toast.error(e?.response?.data?.error)
        })
        GetRatings(page,"5",sortbyrating,brik_id,sortbydate).then((res)=>
        {
            setratingdata(res)
            dispatch(RatingAction(res))
            setisloading({...isloading,rating:false})
        }).catch((e)=>
        {
            setisloading({...isloading,rating:false})
            // toast.error("Something went wrong")
        })


        if(storedata?.propertyreducer?.allproperties?.length!=0)
        {
            setpropdata(storedata?.propertyreducer?.allproperties)
           
        }
        else
        {
            GetAllProperties().then((res)=>
            {
                setpropdata(res)
                dispatch(GetAllPropertiesAction(res))
                
            }).catch((err)=>
            {
                // toast.error("Something went wrong")
            })
        }
    },[page,spv_id,brik_id,confirmbuttonstate])

    useEffect(()=>
    {
        GetRatingsbyRate(page,"5",sortbyrating,brik_id).then((res)=>
        {
            setratingdata(res)
            dispatch(RatingAction(res))
            setisloading({...isloading,rating:false})
        }).catch((e)=>
        {
            setisloading({...isloading,rating:false})
            // toast.error("Something went wrong")
        })
    },[sortbyrating])

    useEffect(()=>
    {
        GetRatingsbyDate(page,"5",brik_id,sortbydate).then((res)=>
        {
            setratingdata(res)
            dispatch(RatingAction(res))
            setisloading({...isloading,rating:false})
        }).catch((e)=>
        {
            setisloading({...isloading,rating:false})
            // toast.error("Something went wrong")
        })
    },[sortbydate])

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div className={className} style={{ ...style, display: 'block', position: 'absolute', right: '-15px'  }} onClick={onClick}>
            <img src={ScrollRight} style={{ width: "40px", height: "40px", maxWidth: 'none' }} alt="Previous" />
          </div>
        );
      };
   
      function PrevArrow(props) {
        const { className, style, onClick } = props;
    
  return (
    <div className={className} style={{ ...style, display: 'block', position: 'absolute', left: '-35px', zIndex: -1}} onClick={onClick}>
      <img src={ScrollLeft} style={{ width: "40px", height: "40px", maxWidth: 'none'}}/>
    </div>
  );
};

    var settings = {
             dots: true,
            infinite: true,
            autoplay: true,
            // swipeToSlide: true,
            centerPadding: "15px",           
            speed: 500,
            autoplaySpeed: 8000,
            adaptiveHeight: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            
      responsive: [
        {
            breakpoint: 3000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              prevArrow: <PrevArrow/>,
              nextArrow: <NextArrow/>,
            draggable: false,

            }
          },
        {
          breakpoint: 1330,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>,
            draggable: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        }
      ]          
      };
      const [dotColor, setDotColor] = useState('');
  const [activeDotColor, setActiveDotColor] = useState('#C69130');
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true , 
    draggable: true,      // Enable mouse/touch dragging
        dragFree: false,      // Snaps to slides after drag
        containScroll: true   // Ensures proper scroll behavior
  })

  const scrollPrev = useCallback(() => {
      if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
      if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])
    
    return(
        isloading.detail&&isloading.rating?<Loading />
        :
        
        <div className="sm:px-[16px] lxl:px-[80px] lg:pr-[20px] lg:pl-[40px] py-[15px] bg-white">
            
            <BackButton color="2D2D2D" url={Back} />
            <div className="flex lg:flex-row sm:flex-col sm:gap-[20px] lxl:gap-[40px] w-full">
                <div className="lg:w-[72%] sm:w-full">
                    
                    <div ref={propdetailnavbar[0]["About"]} className="flex sm:flex-col lg:flex-row items-start sm:gap-[11px] lg:gap-[20px]">
                    {/* <Fade>
                        <div id="propertyimage" className="w-full lg:h-[500px] lg:relative">
                            <img className="w-full h-full object-cover" src={detaildata?.brik_cover_image} />
                            <div className="lg:flex w-full justify-end top-[88%] pr-[22px] absolute sm:hidden">
                                <div id="showallphotos" onClick={()=>setzoomimagemodal({...zoomimagemodal,lg:true})} className="cursor-pointer px-[14px] py-[12px]">
                                    <p className="text-[14px] text-[#0C0C0C] font-[600] leading-[17px]">SHOW ALL PHOTOS</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-[30%] sm:w-full sm:h-[50%] lg:h-[500px] flex sm:flex-row lg:flex-col sm:gap-[11px] lg:gap-[20px]">
                            <div id="propertyimage" className="lg:h-[153px] w-full">
                                <img className="w-full h-full object-cover" src={detaildata?.brik_image?.[0]?.image}/>
                            </div>
                            <div id="propertyimage" className="lg:h-[153px] w-full">
                                <img className="w-full h-full object-cover" src={detaildata?.brik_image?.[1]?.image}/>
                            </div>
                            <div id="propertyimage" className="lg:h-[153px] w-full relative"> 
                                <img className="w-full h-full object-cover " src={detaildata?.brik_image?.[2]?.image}/>
                                <div id="activeeffect" onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:true})} style={{background:"linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.64) 100%)"}} className="absolute top-0 h-full w-full sm:flex flex-col justify-center items-center lg:hidden p-[26px] text-center text-white text-[10px] font-[600] gap-[6px]">
                                    <div className="w-[24px] h-[24px]">
                                        <img src={ShowAll} />
                                    </div>
                                    <p className="">SHOW ALL PHOTOS</p>
                                </div>
                            </div>
                        </div>
                        </Fade> */}
                            <Fade>
                                <div id="propertyimage" className="w-full lg:h-[500px] lg:relative">
                                    <div className={isImageLoading ? "lg:hidden sm:block" : "hidden"}>
                                        {showLoader()}
                                    </div>
                                    <div>
                                    <div className='flex lg:hidden absolute right-5'>
                                        {urlFor360View ? (
                                          <div>
                                           <button onClick={()=>setzoom360viewmodal({...zoom360viewmodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]"><img src= {View360} width= "40px" height={ "40px"}/></button>
                                        </div> )
                                        :<></> }
                                        {urlForPropertyVideo ?(
                                       <div>
                                           <button onClick={()=>setzoompropertyvideomodal({...zoompropertyvideomodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]"><img src= {propertyVideo} width= "40px" height={ "40px"}/></button>
                                          </div>) : <></>}
                                            </div> 
                                    <img className="w-full h-full object-cover lg:hidden sm:block" src={detaildata?.brik_cover_image} onLoad={handleImageLoad} />                                    
                                                                             
                                     </div>
                                    <div className="lg:block sm:hidden">
                                    {/* <Swiper
                                        modules={[Pagination, Navigation, Autoplay]}
                                        direction='horizontal'
                                        navigation={{
                                            prevEl: navigationPrevRef.current,
                                            nextEl: navigationNextRef.current,

                                        }}
                                        //   pagination={{
                                        //     type: 'fraction',
                                        //   }}
                                        onBeforeInit={(swiper) => {
                                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                                            swiper.params.navigation.nextEl = navigationNextRef.current;
                                        }}
                                        loop="true"
                                        >
                                        {
                                            brik_image?.map((e, i) => {
                                                return (
                                                    <SwiperSlide className={`relative`} key={i}>
                                                        <div className='w-full h-[500px]'>
                                                            <img className='w-full h-full object-cover' src={e?.image} />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                        <div className='w-full absolute top-[50%] z-10 flex justify-between'>
                                            <div id='activeeffect' className='' ref={navigationPrevRef}><img src={LeftScroll} /></div>
                                            <div id='activeeffect' className='' ref={navigationNextRef}><img src={RightScroll} /></div>
                                        </div>
                                        <div className='absolute top-[2%] right-[2%] z-10 flex'>
                                        {urlFor360View ? (
                                        <div className="">
                                            
                                     <div onClick={()=>setzoom360viewmodal({...zoom360viewmodal,lg:true})} 
                                     className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]"><img src= {View360}/></div>
                                    </div>) : <></> }

                                      {urlForPropertyVideo ?(                                 
                                    <div className="">
                                     <div onClick={()=>setzoompropertyvideomodal({...zoompropertyvideomodal,lg:true})} 
                                     className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]"><img src= {propertyVideo}/></div>
                                     </div>) :<></> }
                                            
                                        </div>   
                                        
                                    </Swiper> */}
                                     <div className="relative">
            <div className="overflow-hidden" ref={emblaRef}>
                <div className="flex">
                    {brik_image?.map((e, i) => (
                        <div className="flex-[0_0_100%]" key={i}>
                            <div className='w-full h-[500px]'>
                                <img 
                                    className='w-full h-full object-cover' 
                                    src={e?.image} 
                                    alt={`Slide ${i + 1}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Navigation buttons */}
            <div className='w-full absolute top-[50%] z-10 flex justify-between'>
                <div 
                    onClick={scrollPrev}
                    className='cursor-pointer'
                    id='activeeffect'
                >
                    <img src={LeftScroll} alt="Previous" />
                </div>
                <div 
                    onClick={scrollNext}
                    className='cursor-pointer'
                    id='activeeffect'
                >
                    <img src={RightScroll} alt="Next" />
                </div>
            </div>

            {/* 360 View and Video buttons */}
            <div className='absolute top-[2%] right-[2%] z-10 flex'>
                {urlFor360View && (
                    <div className="">
                        <div 
                            onClick={() => setzoom360viewmodal({...zoom360viewmodal, lg: true})} 
                            className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]"
                        >
                            <img src={View360} alt="360 View" />
                        </div>
                    </div>
                )}
                
                {urlForPropertyVideo && (
                    <div className="">
                        <div 
                            onClick={() => setzoompropertyvideomodal({...zoompropertyvideomodal, lg: true})} 
                            className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]"
                        >
                            <img src={propertyVideo} alt="Property Video" />
                        </div>
                    </div>
                )}
            </div>
        </div>
                                    </div>
                                    <div className="lg:flex w-full justify-end top-[88%] pr-[22px] absolute sm:hidden">
                                        <div id="showallphotos" onClick={() => { console.log("SHOW ALL PHOTOS"); setzoomimagemodal({ ...zoomimagemodal, lg: true }) }} className="cursor-pointer px-[14px] py-[12px] z-10 rounded-lg">
                                            <p className="text-[14px] text-[#0C0C0C] font-[600] leading-[17px]">SHOW ALL PHOTOS</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:w-[30%] sm:w-full sm:h-[50%] lg:h-[500px] flex sm:flex-row lg:hidden sm:gap-[11px] lg:gap-[20px]">
                                    <div id="propertyimage" className="lg:h-[153px] w-full">
                                        <img className="w-full h-full object-cover" src={detaildata?.brik_image?.[0]?.image} />
                                    </div>
                                    <div id="propertyimage" className="lg:h-[153px] w-full">
                                        <img className="w-full h-full object-cover" src={detaildata?.brik_image?.[1]?.image} />
                                    </div>
                                    <div id="propertyimage" className="lg:h-[153px] w-full relative">
                                        <img className="w-full h-full object-cover " src={detaildata?.brik_image?.[2]?.image} />
                                        <div id="activeeffect" onClick={() => setzoomimagemodal({ ...zoomimagemodal, sm: true })} style={{ background: "linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.64) 100%)" }} className="absolute top-0 h-full w-full sm:flex flex-col justify-center items-center lg:hidden p-[26px] text-center text-white text-[10px] font-[600] gap-[6px]">
                                            <div className="w-[24px] h-[24px]">
                                                <img src={ShowAll} />
                                            </div>
                                            <p className="">SHOW ALL PHOTOS</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                    </div>
                    

                    <ModalForImages zoomimagemodal={zoomimagemodal} setzoomimagemodal={setzoomimagemodal}/>
                    <ModalForAmenities zoomimagemodal={zoomamenitiesmodal} setzoomimagemodal={setzoomamenitiesmodal} />
                    <ModalForAddOns zoomimagemodal={zoomaddonmodal} setzoomimagemodal={setzoomaddonmodal}/>
                    <ModalForHotelRules zoomimagemodal={zoomhotelrulesmodal} setzoomimagemodal={setzoomhotelrulesmodal} />
                    <ModalForSafetyProperty zoomimagemodal={zoomsafetypropertymodal} setzoomimagemodal={setzoomsafetypropertymodal} />
                    <ModalForCancellationPolicy zoomimagemodal={zoomcancellationpolicymodal} setzoomimagemodal={setzoomcancellationpolicymodal} />
                    <ModalForPetsFriendly zoomimagemodal={zoompetsfriendlymodal} setzoomimagemodal={setzoompetsfriendlymodal} />
                    <ModalFor360View zoomimagemodal={zoom360viewmodal} setzoomimagemodal={setzoom360viewmodal} />
                    <ModalForPropertyVideo zoomimagemodal={zoompropertyvideomodal} setzoomimagemodal={setzoompropertyvideomodal} />
                    
                    <div className="sm:mt-[20px] lg:mt-[40px]">
                    <Fade>
                        <div style={{fontFamily:"Poppins"}} className="flex lg:flex-row sm:flex-col sm:gap-[10px] justify-between items-center sm:mb-[15px] lg:mb-[40px]">
                            <div className="flex flex-col gap-[10px] sm:w-full lg:w-[80%]">
                                <p style={{fontFamily:"Poppins"}} className="font-[600] sm:text-[20px] lg:text-[24px] sm:leading-[30px] lg:leading-[45px] text-[#0C0C0C]">{detaildata?.property_name}</p>
                                <p className="text-[#656565] sm:text-[14px] lg:text-[16px] font-[400] leading-[24px]">{detaildata?.location}</p>
                            </div>
                            {/* <div style={{fontFamily:"Inter"}} className="lg:w-[18%] sm:w-full flex flex-col gap-[10px] text-[#0C0C0C] bg-white p-[10px]">
                                <p className="font-[600] sm:text-[18px] lg:text-[20px] leading-[20px]">From ₹{detaildata?.per_night_inr}</p>
                                <p className="text-[#656565] font-[400] sm:text-[16px] lg:text-[18px] leading-[21px]">per night</p>
                            </div> */}
                        </div>
                        </Fade>
                    </div>
                    
                    <hr className="text-[#E6E6E6] border-[1px] mb-[40px]"/>
                    
                    <Amenities bed={`${detaildata?.bedroom} Bedrooms`} guest={`${detaildata?.accomadation} Guests`} area={`${detaildata?.property_area} sqft.`} view={`${detaildata?.property_view}`} />
                    <hr className="lg:block sm:hidden text-[#E6E6E6] border-[1px] my-[40px]"/>
                    
                    <div className="sm:block lg:hidden sm:w-full lg:w-[35%] border-2 rounded-lg p-[15px] mt-[50px] h-full flex flex-col">
                    {/* <div style={{fontFamily:"Inter"}} className="lg:hidden sm:block flex gap-[20px] mb-[40px] text-[18px] text-[#BEBEBE] font-[500] leading-[30px]">
                        {data?.map((e,i)=>
                        {
                            return <p className={selecttab==i?"text-black cursor-pointer border-b-4 border-black":"cursor-pointer"} key={i} onClick={()=>setselecttab(i)}>{e}</p>
                        })}
                    </div> */}
                    {
                        selecttab==0?(
                            // <div style={{fontFamily:"Inter"}} className="flex flex-col gap-[20px]">
                            //     <CalendarCombined type="single"  />
                            //     <InputSelector label="Room" text={`${roomcount}`} gap="40px" memberdisplay={roomcountdisplay} setmemberdisplay={setroomcountdisplay} />
                            //     <RoomSelector memberdisplay={roomcountdisplay} setmemberdisplay={setroomcountdisplay} /> 
                            //     <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="40px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                            //     <MembersCount memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay}/>
                            //     <NavButton name="BOOK NOW" classname="bg-[#C69130] px-[25px] py-[15px] text-white text-[16px] font-[060] leading-[19px] tracking-[1.5px]"/>
                            // </div>
                            <div style={{ fontFamily: "Poppins"}} className="flex flex-col gap-[10px]">
                                {/* <p style={{fontFamily:"Playfair Display"}} className="text-[#0C0C0C] text-[28px] font-[600] leading-[37px] mb-[20px]">Check availability</p> */}
                                {/* <PlaceSelectorDetailView label="Where" gap="100px"/> */}
                                <div>
                                    <p style={{fontFamily:"Poppins"}} className=" text-[#2D2D2D] text-[20px] font-[600]">₹{detaildata?.per_night_inr} <span className="text-[#2D2D2D] text-[16px] font-[400]">per Night</span></p>
                                </div>
                                <div className="border-2 rounded-md w-full">
                                    <CalendarCombined type="multiple" />
                                </div>
                                <div className="border-2 rounded-md w-full">
                                <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="10px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                                    <MembersCountDetailView setdetaildata={setdetaildata} setconfirmbuttonstate={setconfirmbuttonstate} maxAdult={detaildata?.accomadation} />
                                    {adult > detaildata?.accomadation ? <p className='text-[#FF0000] text-[14px] text-center font-[400] leading-[20px]'>Exceeded maximum guest limit with added guest information.</p>:<></>}

                                </div>
                                    <NavButton disabled={!detaildata.brik_availability} onClick={() => {
                                        if(detaildata?.brik_availability)
                                        {
                                            localStorage.setItem("booking_detail", JSON.stringify({ 'brik_id':brik_id, 'spv_id':spv_id }));
                                            navigate("/checkout");
                                            // window.scrollTo({
                                            //     top: 0,
                                            //     behavior: 'smooth'
                                            // });
                                            smoothScrolltoTop()
                                        }
                                        else if (adult > detaildata?.accomadation){
                                            toast.error("Bookings not allowed on the specific dates!!")
                                        }
                                        else
                                        {
                                            toast.error("Bookings not allowed on the specific dates!!")
                                        }
                                        
                                    }}
                                     name={searchquery?"UPDATE SEARCH":"BOOK NOW"} classname={!detaildata?.brik_availability ? "rounded-lg cursor-auto bg-[grey] px-[22px] py-[21px] text-white sm:text-[14px] lg:text-[16px] font-[700] leading-[19px] tracking-[0.8px]":"bg-[#C69130] px-[22px] py-[21px] text-white sm:text-[14px] lg:text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976E23] rounded-lg"} />

                            </div>
                        )
                        :
                        <></>
                    }
                    
                 </div>

                    {
                    detaildata?.lat && detaildata.lng?
                    <div ref={propdetailnavbar[0]["Location"]} className="my-[50px]">
                        <Fade>
                        <p style={{fontFamily:"Poppins"}} className="text-[#2D2D2D text-[22px] font-[600] leading-[33px] mb-[25px]">Location</p>
                        
                        <Box>
                        
                        <Map latitude={detaildata?.lat} longitude={detaildata?.lng} />
                        </Box>
                        </Fade>
                    </div>:<></>
                    }
                        <div style={{fontFamily:"Poppins"}} className="flex flex-col gap-[25px] sm:mt-[55px] lg:mt-[0px]">
                        <Fade>
                            <p style={{fontFamily:"Poppins"}} className="text-[#2D2D2D] text-[22px] font-[600] leading-[33px]">About</p>
                            {
                                detaildata?.description?.length<=200?
                                <p className="text-[#656565] text-[16px] font-[400] leading-[30px]">{detaildata?.description} </p>:
                                <p className="text-[#656565] text-[16px] font-[400] leading-[30px]">{truncatedtext} <span onClick={()=>truncatedtext.length=="200"?settruncatedtext(detaildata?.description):settruncatedtext(detaildata?.description?.slice(0,200))} className="text-[#D69F56] cursor-pointer">{truncatedtext?.length=="200"?"read more...":" read less..."}</span></p>
                            }
                        </Fade>
                        </div>
                    
                    
                    <div className="sm:mt-[55px] lg:mt-[0px]" ref={propdetailnavbar[0]["RoomAmenities"]}>
                    <Fade>
                    <hr className="lg:block sm:hidden text-[#656565] border-[1px] my-[40px]"/>
                    {
                        detaildata?.amenities?.length!=0?
                        <RoomAmenities data={detaildata?.amenities} type="amenities" title="Room Amenities" zoomimagemodal={zoomamenitiesmodal} setzoomimagemodal={setzoomamenitiesmodal} />:
                        <></>
                    }
                    </Fade>
                    </div>
                    
                    
                        <div ref={propdetailnavbar[0]["AddOn"]}>
                        <Fade>
                        {
                            detaildata?.add_on_services?.length!=0?
                            <RoomAmenities data={detaildata?.add_on_services} type="add" title="Add on services" zoomimagemodal={zoomaddonmodal} setzoomimagemodal={setzoomaddonmodal} />:
                            <></>
                        }
                        </Fade>
                        </div>
                    
                        <div ref={propdetailnavbar[0]["PetsAllowed"]}>
                            <Fade>
                                {
                                //    detaildata?.pets_allowed && detaildata?.pets_allowed != "" ?
                                detaildata?.pets_allowed ?
                                       <div className="my-[30px]">
                                            <p style={{ fontFamily: "Poppins" }} className="text-[#2D2D2D] text-[22px] font-[600] leading-[33px] mb-[10px]">Pets Allowed</p>
                                            <div className="flex items-center gap-[10px]">
                                                 <img src={PetsAllowedImage}/><p>Pets Allowed</p>
                                                    {/* <img src={HotelRules}></img>
                                                </div>
                                                <a href={detaildata?.pets_allowed} target="blank" style={{ fontFamily: "Playfair Display" }} className="underline cursor-pointer text-[#D29A67] text-center font-[600] text-[18px] leading-[21px]">View Pets Allowed</a> */}
                                            </div>
                                            <hr className="lg:block sm:hidden text-[#2D2D2D] border-[1px] my-[40px]" />

                                        </div>
                                        : <></>
                                }
                            </Fade>
                        </div>
                    
                        <div className=" lg:mt-[0px]" ref={propdetailnavbar[0]["Review"]}>
                        <Fade>
                            <Reviews page={page} setpage={setpage} reviewdata={ratingdata} detaildata={detaildata} sortbydate={sortbydate} setsortbydate={setsortbydate} sortbyrating={sortbyrating} setsortbyrating={setsortbyrating}/>
                        </Fade>
                        </div>
                        <hr className="lg:block sm:hidden text-[#2D2D2D] border-[1px] my-[40px]" />

                        {HTMLRule !== "" || HTMLCancellation !== "" || HTMLPetsFriendly !== "" || HTMLSafety !== "" ? (
    <Fade>
        <div>
            <p style={{ fontFamily: "Poppins" }} className="text-[#0C0C0C] text-[22px] font-[600] leading-[33px] mb-[10px] mt-[10px]" ref={propdetailnavbar[0]["ThingsToKnow"]}>
                Things To Know
            </p>
            <div className={`grid ${countContent > 2 ? "sm:grid-cols-1 lg:grid-cols-2 lg:grid-rows-2" : "sm:grid-cols-1 lg:grid-cols-2"} justify-center mt-[27px] items-start sm:gap-x-[20px] sm:gap-y-[20px] lg:gap-y-[20px] gap-x-[160px]`}>
                {HTMLRule !== "" ? (
                    <div className=" border-2 shadow-md rounded-md">
                      <div className="my-[15px] mx-[15px]">
                                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[18px] font-[600] leading-[33px] mb-[10px]">House Rules</p>
                        <div className="flex items-center gap-[10px]">
                                    {/* <div>
                                        <img src={HotelRules}></img>
                                    </div> */}
                                    {/* <a href={detaildata?.hotel_rules} target="blank" style={{fontFamily:"Playfair Display"}} className="underline cursor-pointer text-[#D29A67] text-center font-[600] text-[18px] leading-[21px]">View House Rules</a> */}
                                </div>
                                <div>
                                    {/* {
                                        detaildata?.hotel_rules?.map((e,i)=>
                                        {
                                            if(i<id)
                                            {
                                                return <ImageTextCard key={i} text={e?.rule}/>
                                            }
                                        })
                                    } */}
                                   {HTMLRule && (<div dangerouslySetInnerHTML={{__html:HTMLRule}} className="HTMLContentStyle md: h-[120px] lg: h-[100px] overflow-hidden"
                                    />)}
                                    <div className="sm:hidden lg:block">
                                     <div onClick={()=>setzoomhotelrulesmodal({...zoomhotelrulesmodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]">See More</div>
                                    </div>                                    

                                    <div className="sm:block lg:hidden">
                                        <div style={{display:detaildata?.hotel_rules?.rule?.length>=id?"block":"none"}} id="activeeffect" className=" border-[1px] border-[#C69130] rounded-md mt-[5px]">
                                           <button onClick={()=>setzoomhotelrulesmodal({...zoomhotelrulesmodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]">See More</button>
                                           {/* <button onClick={()=>setzoomhotelrulesmodal({...zoomhotelrulesmodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[12px] font-[400] text-[14px] leading-[21px]">See More...</button>
                                        </div>
                                    </div>
                                </div>
                                <div><p className="text-[#2D2D2D] text-[18px] font-[600] leading-[33px] mb-[10px]">Safety & Property</p>
                                {        <ul className="text-[#656565] text-[16px] font-[400]">
                                        <li>No carbon monoxide alarm</li>
                                        <li>No smoke alarm</li>
                                       </ul>               
                                    }
                                    <div className="sm:hidden lg:block">
                                        <div  style={{display:"block"}} id="activeeffect" className="w-full">
                                            <div onClick={()=>setzoomsafetypropertymodal({...zoomsafetypropertymodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[400] text-[16px] leading-[21px]">See More...</div>
                                        </div>
                                    </div>                                    
                                    <div className="sm:block lg:hidden">
                                        <div style={{display:"block"}} id="activeeffect" className=" border-[1px] border-[#C69130]">
                                            <button onClick={()=>setzoomsafetypropertymodal({...zoomsafetypropertymodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[12px] font-[400] text-[14px] leading-[21px]">See More...</button>
                                        </div>
                                    </div>
                                    </div>
                                <div><p className="text-[#2D2D2D] text-[18px] font-[600] leading-[33px] mb-[10px]">Cancellation Policy</p>
                                {
                                       <ul className="text-[#656565] text-[16px] font-[400]">
                                        <li>Before 21 days (about 3 weeks) of check-in – 25% Deduction of...</li>
                                       </ul>
                                    }
                                    <div className="sm:hidden lg:block">
                                        <div  style={{display:"block"}} id="activeeffect" className="w-full">
                                            <div onClick={()=>setzoomcancellationpolicymodal({...zoomcancellationpolicymodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[400] text-[16px] leading-[21px]">See More...</div>
                                        </div>
                                    </div>                                    
                                    <div className="sm:block lg:hidden">
                                        <div style={{display:"block"}} id="activeeffect" className=" border-[1px] border-[#C69130]">
                                            <button onClick={()=>setzoomcancellationpolicymodal({...zoomcancellationpolicymodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[12px] font-[400] text-[14px] leading-[21px]">See More...</button>
                                        </div> */}
                                    </div>
                                    </div>
                            </div>
                            </div>
                    </div>
                ) : (
                    <></>
                )}
                {HTMLSafety !== "" ? (
                    <div className=" border-2 shadow-md rounded-md">
                       <div className="my-[15px] mx-[15px]">
                                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[18px] font-[600] leading-[33px] mb-[10px]">Safety And Property</p>
                             <div className="flex items-center gap-[10px]">
                                   
                                </div>

                                <div>
                                    {HTMLSafety && (<div dangerouslySetInnerHTML={{__html:HTMLSafety}}className="HTMLContentStyle md: h-[120px] lg: h-[100px] overflow-hidden"/>)}                                                                     
                                    
                                    <div className="sm:hidden lg:block">
                                     <div onClick={()=>setzoomsafetypropertymodal({...zoomsafetypropertymodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]">See More</div>
                                    </div>                                    

                                    <div className="sm:block lg:hidden">
                                        <div style={{display:detaildata?.safety_and_property?.safetyAndProperty?.length>=id?"block":"none"}} id="activeeffect" className=" border-[1px] border-[#C69130] rounded-md mt-[5px]">
                                           <button onClick={()=>setzoomsafetypropertymodal({...zoomsafetypropertymodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]">See More</button>
                                          </div>
                                    </div>
                            </div>
                            </div>
                    </div>
                ) : (
                    <></>
                )}
                {HTMLCancellation !== "" ? (
                    <div className=" border-2 shadow-md rounded-md">
                        <div className="my-[15px] mx-[15px]">
                                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[18px] font-[600] leading-[33px] mb-[10px]">Cancellation Policy</p>
                             <div className="flex items-center gap-[10px]">                                  
                                </div>
                                <div>
                                   
                                   {HTMLCancellation && (<div dangerouslySetInnerHTML={{__html:HTMLCancellation}}className="HTMLContentStyle md: h-[120px] lg: h-[100px] overflow-hidden"/>)}                                                                     
                                    
                                    <div className="sm:hidden lg:block">
                                     <div onClick={()=>setzoomcancellationpolicymodal({...zoomcancellationpolicymodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]">See More</div>
                                    </div>                                    

                                    <div className="sm:block lg:hidden">
                                        <div style={{display:detaildata?.cancellation_policy?.cancellationPolicy?.length>=id?"block":"none"}} id="activeeffect" className=" border-[1px] border-[#C69130] rounded-md mt-[5px]">
                                           <button onClick={()=>setzoomcancellationpolicymodal({...zoomcancellationpolicymodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]">See More</button>
                                          
                                    </div>
                                    </div>
                            </div>
                            </div>
                    </div>
                ) : (
                    <></>
                )}
                {HTMLPetsFriendly !== "" ? (
                    <div className=" border-2 shadow-md rounded-md">
                       <div className="my-[15px] mx-[15px]">
                                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[18px] font-[600] leading-[33px] mb-[10px]">Pets Friendly</p>
                             <div className="flex items-center gap-[10px]">
                                   
                                </div>

                                <div>
                                    {HTMLPetsFriendly && (<div dangerouslySetInnerHTML={{__html:HTMLPetsFriendly}}className="HTMLContentStyle md: h-[120px] lg: h-[100px] overflow-hidden"/>)}                                                                     
                                    
                                    <div className="sm:hidden lg:block">
                                     <div onClick={()=>setzoompetsfriendlymodal({...zoompetsfriendlymodal,lg:true})} className="cursor-pointer text-[#D29A67] text-left font-[600] text-[16px] leading-[21px]">See More</div>
                                    </div>                                    

                                    <div className="sm:block lg:hidden">
                                        <div style={{display:detaildata?.pets_friendly?.petsFriendly?.length>=id?"block":"none"}} id="activeeffect" className=" border-[1px] border-[#C69130] rounded-md mt-[5px]">
                                           <button onClick={()=>setzoompetsfriendlymodal({...zoompetsfriendlymodal,sm:true})} className="cursor-pointer w-full text-[#D29A67] py-[9px] font-[600] text-[14px] leading-[21px]">See More</button>
                                          </div>
                                    </div>
                            </div>
                            </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <hr className="lg:block sm:hidden text-[#2D2D2D] border-[1px] my-[40px]" />
        </div>
    </Fade>
) : (
    <></>
)}
              
                </div>
                
                
                <div style={{position:"sticky", top:100}} className="sm:hidden lg:block border-2 rounded-lg p-[15px] h-full flex flex-col">
                    <div style={{fontFamily:"Poppins"}} className="lg:hidden sm:block flex gap-[20px] mb-[40px] text-[18px] text-[#BEBEBE] font-[500] leading-[30px]">
                        {data?.map((e,i)=>
                        {
                            return <p className={selecttab==i?"text-black cursor-pointer border-b-4 border-black":"cursor-pointer"} key={i} onClick={()=>setselecttab(i)}>{e}</p>
                        })}
                    </div>
                    {
                        selecttab==0?(
                            // <div style={{fontFamily:"Inter"}} className="flex flex-col gap-[20px]">
                            //     <CalendarCombined type="single"  />
                            //     <InputSelector label="Room" text={`${roomcount}`} gap="40px" memberdisplay={roomcountdisplay} setmemberdisplay={setroomcountdisplay} />
                            //     <RoomSelector memberdisplay={roomcountdisplay} setmemberdisplay={setroomcountdisplay} /> 
                            //     <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="40px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                            //     <MembersCount memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay}/>
                            //     <NavButton name="BOOK NOW" classname="bg-[#C69130] px-[25px] py-[15px] text-white text-[16px] font-[060] leading-[19px] tracking-[1.5px]"/>
                            // </div>
                            <div style={{ fontFamily: "Poppins"}} className="flex flex-col gap-[10px]">
                                {/* <p style={{fontFamily:"Playfair Display"}} className="text-[#0C0C0C] text-[28px] font-[600] leading-[37px] mb-[20px]">Check availability</p> */}
                                {/* <PlaceSelectorDetailView label="Where" gap="100px"/> */}
                                <div>
                                    <p style={{fontFamily:"Poppins"}} className=" text-[#2D2D2D] text-[20px] font-[600]">₹{detaildata?.per_night_inr} <span className="text-[#2D2D2D] text-[16px] font-[400]">per Night</span></p>
                                </div>
                                <div className="border-2 rounded-md w-full">
                                    <CalendarCombined type="multiple" />
                                </div>
                                <div className="border-2 rounded-md w-full">
                                <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="10px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                                    <MembersCountDetailView setdetaildata={setdetaildata} setconfirmbuttonstate={setconfirmbuttonstate} maxAdult={detaildata?.accomadation} />
                                    {adult > detaildata?.accomadation ? <p className='text-[#656565] text-[14px] text-center font-[400] leading-[20px]'>Exceeded maximum guest limit with added guest information.</p>:<></>}

                                </div>
                                    <NavButton disabled={!detaildata.brik_availability} onClick={() => {
                                        if(detaildata?.brik_availability)
                                        {
                                            localStorage.setItem("booking_detail", JSON.stringify({ 'brik_id':brik_id, 'spv_id':spv_id }));
                                            navigate("/checkout");
                                            // window.scrollTo({
                                            //     top: 0,
                                            //     behavior: 'smooth'
                                            // });
                                            smoothScrolltoTop()
                                        }
                                        else if (adult > detaildata?.accomadation){
                                            toast.error("Bookings not allowed on the specific dates. Please change your dates")
                                        }
                                        else
                                        {
                                            toast.error("Bookings not allowed on the specific dates. Please change your dates")
                                        }
                                        
                                    }}
                                     name={searchquery?"UPDATE SEARCH":"BOOK NOW"} classname={!detaildata?.brik_availability ? "rounded-lg cursor-auto bg-[grey] px-[22px] py-[21px] text-white sm:text-[14px] lg:text-[16px] font-[700] leading-[19px] tracking-[0.8px]":"rounded-md bg-[#C69130] px-[22px] py-[21px] text-white sm:text-[14px] lg:text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976E23] rounded-lg"} />

                            </div>
                        )
                        :
                        <></>
                    }
                    
                </div>
            </div>
         {/* <Fade>
            <div  className="mt-[20px] xl:block md:hidden sm:hidden">
                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[20px] font-[600] leading-[33px]">More Properties</p>
             <div>
               <Swiper
                    ref={propdetailnavbar[0]["MoreProperties"]}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="myswiper"
                    direction='horizontal'
                    // ref= {swiperRef}
                    pagination= {{
                        el: '.swiper-custom-pagination',
                        clickable:true
                    }}                
                    slidesPerView="4"
                    slidesPerGroup="4"
                    spaceBetween={15}
                    loop= {true} 
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    
                    >
                {
                    propdata?.map((e,i)=>
                    {
                        let {property_name,property_area,brik_cover_image,bedroom,accomadation,per_night_inr,property_view, location}=e?.brik_data;
                        // if(e?.brik_data?.brik_id!=brik_id)
                        // {
                        return(
                            <SwiperSlide key={i}> 
                                <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id}  bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name}  location={location}/>
                            </SwiperSlide>
                            
                        )
                         } 
                     )}
                 <div className='w-full absolute top-[50%] z-20 flex justify-between'>
                   <div id='activeeffect' className='' ref={navigationPrevRef}><img src={ScrollLeft} /></div>
                  <div id='activeeffect' className='' ref={navigationNextRef}><img src={ScrollRight} /></div>
                </div>
                 <div className="swiper-custom-pagination"/>
            </Swiper>  
         </div>
            
        </div>
    </Fade> */}
    
<Fade>
      {/* <div className="mt-[20px] xl:block md:hidden sm:hidden"> */}
      <div className="mt-[20px]">
        <p style={{ fontFamily: "Poppins" }} className="text-[#0C0C0C] text-[20px] font-[600] leading-[33px]">More Properties</p>
        <div className="pb-[20px] pr-[20px]">
          <Slider {...settings}>
            {propdata?.map((e, i) => {
              let {property_name, property_area, spv_cover_image, is_test_spv,bedroom, accomadation, per_night_inr, property_view, location} = e?.brik_data;
              if(is_test_spv){
                return 
              }
              return (
                <div key={i} className="pr-[7.5px] pl-[7.5px]">
                  <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={spv_cover_image} price={per_night_inr} guests={`${accomadation} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                </div>
              );
            })}             
          </Slider>  
          <style>
        {`
        .slick-dots {
            display: flex !important;
            list-style: none;
            justify-content: center; 
          }
          .slick-dots li button:before {
            font-size: 10px;
          }
          .slick-dots li.slick-active button:before {
            color: ${activeDotColor};
            font-size: 10px;
          }
          .slick-dots li button:hover:before{
            color: ${activeDotColor};
          }
          .slick-dots {
            text-align: center;
          }
          .slick-dots li {
              display: none;
            margin: 0 5px;
          }
          .slick-dots li.slick-active,
          .slick-dots li.slick-active + li,
          .slick-dots li.slick-active + li + li {
              display: inline-block;
          }
          
          .slick-dots li:nth-last-child(1),
          .slick-dots li:nth-last-child(2),
          .slick-dots li:nth-last-child(3) {
              display: inline-block;
          }
          
          .slick-dots li.slick-active ~ li:nth-last-child(1),
          .slick-dots li.slick-active ~ li:nth-last-child(2),
          .slick-dots li.slick-active ~ li:nth-last-child(3) {
              display: none;
          }
          .slick-dots li.slick-active + li + li:nth-last-child(3),
          .slick-dots li.slick-active + li + li:nth-last-child(2),
          .slick-dots li.slick-active + li + li:nth-last-child(1),
          .slick-dots li.slick-active + li:nth-last-child(3),
          .slick-dots li.slick-active + li:nth-last-child(2),
          .slick-dots li.slick-active + li:nth-last-child(1){
              display: inline-block;
          }
        `}
      </style>
        </div>
      </div>
    </Fade>
   
            {/* <Fade>
            <div  className="mt-[20px] xl:hidden md:block sm:hidden">
                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[20px] font-[600] leading-[33px]">More Properties</p> */}
                {/* <Swiper
                    ref={propdetailnavbar[0]["MoreProperties"]}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="myswiper"
                    direction='horizontal'
                    pagination= {{
                        el: '.swiper-custom-pagination',
                        clickable:true
                    }}                
                    slidesPerView="2"
                    // slidesPerGroup="2"
                    spaceBetween={15}
                    loop="true"
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}>
                {
                    propdata?.map((e,i)=>
                    {
                        let {property_name,property_area,brik_cover_image,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                        // if(e?.brik_data?.brik_id!=brik_id)
                        // {
                        return(
                            <SwiperSlide key={i}>
                                <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id}  bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name}  location={location}/>
                            </SwiperSlide>
                        )
                        // }
                    })
                }
                
                <div className="swiper-custom-pagination"/>
            </Swiper> */}
            {/* <div className="pb-[20px]">
          <Slider {...settings}>
            {propdata?.map((e, i) => {
              let {property_name, property_area, brik_cover_image, bedroom, accomadation, per_night_inr, property_view, location} = e?.brik_data;
              return (
                <div key={i} className="pr-[15px]">
                  <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                </div>
              );
            })}
             
          </Slider>  
        </div>
            </div>
            </Fade> */}

            {/* <Fade>
            <div  className="mt-[50px] md:hidden sm:block">
                <p ref={propdetailnavbar[0]["MoreProperties"]} style={{fontFamily:"Poppins"}} className="text-[#2D2D2D] text-[18px] font-[600] leading-[33px]">More Properties</p> */}
                {/* <Swiper                
                    modules={[Pagination, Navigation, Autoplay]}
                    className="myswiper"
                    direction='horizontal'
                    pagination= {{
                        el: '.swiper-custom-pagination',
                        clickable:true
                    }}                
                    slidesPerView="1"
                    // slidesPerGroup="1"
                    spaceBetween={15}
                    loop="true"
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}>
                {
                    propdata?.map((e,i)=>
                    {
                        let {property_name,property_area,brik_cover_image,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                        // if(e?.brik_data?.brik_id!=brik_id)
                        // {
                        return(
                            <SwiperSlide className='swiper-slide' key={i}>
                                <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id}  bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name}  location={location}/>
                            </SwiperSlide>
                        )
                        // }
                    })
                }
                <div className="swiper-custom-pagination"/>
            </Swiper> */}
             {/* <div className="pb-[20px]">
          <Slider {...settings}>
            {propdata?.map((e, i) => {
              let {property_name, property_area, brik_cover_image, bedroom, accomadation, per_night_inr, property_view, location} = e?.brik_data;
              return (
                <div key={i} className="pr-[15px]">
                  <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                </div>
              );
            })}       
          </Slider>  
        </div>
            </div>
            </Fade> */}
        </div>
    )
}
export default About